import React, { Component } from 'react';
import './Event.css';
import AdminPanel from './AdminPanel';
import VideoPlayer from './VideoPlayer';
import ExtraVideoPlayer from './ExtraVideoPlayer';

function FormatTime(time) {
    let s,m,h,d;

    s = Math.floor(time/1000);
    m = Math.floor(s/60);
    s %= 60;
    h = Math.floor(m/60);
    m %= 60;
    d = Math.floor(h/24);
    h %= 24;

    const out = [];
    const li = [d,h,m,s];
    const table = ['day', 'hour', 'minute', 'second'];

    

    for (let i = 0; i < li.length; i++) {
        const frag = li[i];
        const end = frag === 1 ? '' : 's';

        if (frag > 0) out.push(`${frag} ${table[i]}${end}`);
    }

    if (out.length === 0) return '';

    return out.join(", ");

}

export default class Event extends Component {

    // eventUnlockTime = Date.now() + 10*1000;
    // extraVidsUnlockTime = Date.now() + 20*1000;
    eventUnlockTime = new Date('Wed, 17 Nov 2021 20:00:00 GMT').getTime();
    extraVidsUnlockTime = new Date('Wed, 17 Nov 2021 21:30:00 GMT').getTime();

    currentRealTime = Date.now();

    constructor(props) {
        super(props)
    
        this.state = {
            adminPanelOpen: false,
            eventData: null,
            vidOpen: false,
            extraVid: null,
            eventStarted: false,
            extrasUnlocked: false,
            eventTimerText: "Event begins on 11/17/2021, 2:00PM CST",
            compatibilityMode: false
        }


    }

    componentDidMount() {

        this.GetEventData();

        this.TestEventTime();
        this.TestExtrasTime();
        

    }

    TestEventTime = () => {

        if (Date.now() >= this.eventUnlockTime) {
            this.setState({eventStarted: true});
        } else {
            this.setState({eventTimerText: 'Event begins in ' + FormatTime(this.eventUnlockTime - Date.now())});
            setTimeout(this.TestEventTime, 1000);
        }

        

    }

    TestExtrasTime = () => {
        if (Date.now() >= this.extraVidsUnlockTime) {
            this.setState({extrasUnlocked: true});
        } else {
            setTimeout(this.TestExtrasTime, 1000);
        }
    }

    GetEventData() {
        fetch('/eventData')
            .then(res => res.json())
            .then(json => {
                if (json.status === 200) {
                    this.setState({eventData: json.content});
                    console.log(json.content);
                } else {
                    console.error(`ERR: ${json.content}`);
                }
            })
            .catch(e => console.error(e));
    }

    MakeVideoList = () => {

        const urls = [];
        const names = [];

        for (let i = 0; i < this.state.eventData.chapters.length; i++) {
            const chapter = this.state.eventData.chapters[i];
            urls.push(chapter.videos[0].url);
            names.push(chapter.title);
        }

        return urls.map((url, i) => {

            url = url.replace("/videos/", "/vids/")

            return (
                <div key={`video_`+url} className="mvidrow">
                    <video controls style={{
                        maxWidth: '100%'
                    }} src={url}></video>
                </div>
            )

        });

    }

    render() {

        const user = this.props.user;

        const vidlist = this.state.eventData ? this.MakeVideoList() : null;

        return (
            <div className="Event">
                <div className="header">
                    <div className="container">
                        <ul>
                            <li><i className="fas fa-user"></i> {this.props.user.firstName} {this.props.user.lastName}</li>
                            {this.props.user.rank >= 1 ? <li><button onClick={() => this.setState({adminPanelOpen: true})}>Admin</button></li> : ''}
                            <li><a href="/logout">Log out</a></li>
                        </ul>
                    </div>
                </div>
                <div className="container" style={{padding: '1rem'}}>
                    <h2 style={{fontSize: '2rem'}} className="yellow">Welcome to our Video Platform Demo</h2>
                    <p style={{fontSize: '1.2rem'}}>We appreciate your interest and have provided this small framework as an example of both login functionality and video content delivery. The videos below are compressed and transcoded using our proprietary compression methodology, providing HD quality with no buffering even when viewed from a mobile device in less-than-optimal conditions. From the full screen player, you can access additional videos using the source icon in the lower right.</p>
                    {/* <p style={{
                        fontSize: '2rem',
                        fontWeight: 'bold',
                        textAlign: 'right'
                    }}>- The Ergon Family</p> */}

                    <div className="info">
                        <div className="infoCol">
                            <p className="title">Need to take a break?</p>
                            <p>You can pause the video or close the browser window and return without logging in again — provided you use the same computer and browser upon return. If needed, your passcode can be retrieved at any time via the website login page.</p>
                            <p className="title">Tracking and Reporting</p>
                            <p>There are a wide array of tracking and reporting solutions that we can make accessible, some of the most common being how many users are logged in, which videos are being watched currently, which videos each user has watched and for how long (i.e. they started a video and didn’t finish it, watched it to completion, etc.)</p>
                        </div>
                        <div className="infoCol">
                            <p className="title">User Management</p>
                            <p>Each administrative user on the platform can create, edit, and delete users at will, with multiple administrators as needed by clients.</p>
                            <p className="title">Confidential information.</p>
                            <p>Please keep in mind that this demo is provided for your use only. If additional users are needed, please let us know and we will add them to the demo database with an access code.</p>
                            {/* <p>Thanks again for your time. We look forward to working with you as we continue to move this great company forward by meeting needs, supporting families and serving customers.</p> */}
                        </div>
                    </div>
                    {/* <div className="notice">
                        <p>Technical issues? Text ███-███-████.<br />Technical ██████████ ████ available during the presentation.</p>
                    </div> */}

                </div>

                <div className="contacts">
                    <div className="container">
                        {/* <div className="contact">
                            <p className="title">Stephen</p>
                            <p>stephen@provisweb.com</p>
                        </div>
                        <div className="contact">
                            <p className="title">Glenn</p>
                            <p>glenn@provissolutions.com</p>
                        </div>
                        <div className="contact">
                            <p className="title">Nick</p>
                            <p>nick@provissolutions.com</p>
                        </div> */}
                        {/* <div className="contact">
                            <p className="title">John Wallace</p>
                            <p>john.wallace@ergon.com</p>
                            <p>601.933.3270</p>
                        </div> */}
                    </div>
                </div>

                {/* <div className="catchup container">
                    <div className="catchupCol">
                        <h3 className="yellow">For anyone who has not participated in ██████████████:</h3>
                        <p>The following videos provide an overview of our history and a glimpse into the values of our founder, <span style={{whiteSpace: 'nowrap'}}>Leslie B. Lampton Sr.</span>, as shared with the third generation of his family.</p>
                    </div>
                    <div className="catchupCol grid c1fr1fr">
                        <div className="catchupVideo">
                            <video src="/vids/catchup1.mp4" controls></video>
                            <div onClick={(e) => {
                                e.currentTarget.parentElement.querySelector("video").play();
                                e.currentTarget.remove();
                            }} className="vidCard" style={{backgroundImage: "url(/images/catchup1card.jpg)"}}><i className="fas fa-play"></i></div>
                        </div>
                        <div className="catchupVideo">
                            <video src="/vids/catchup2.mp4" controls></video>
                            <div onClick={(e) => {
                                e.currentTarget.parentElement.querySelector("video").play();
                                e.currentTarget.remove();
                            }} className="vidCard" style={{backgroundImage: "url(/images/catchup2card.jpg)"}}><i className="fas fa-play"></i></div>
                        </div>
                    </div>
                </div> */}
                
                {this.state.eventStarted ? (
                    <div className="videoContainer">
                        {this.state.compatibilityMode ? (
                            <div className="container">
                                <h2>Video Platform Presentation</h2>
                                <div className="vidgrid">
                                    {/* <div className="mvidrow">
                                        <i className="fas fa-play"></i>
                                    </div> */}
                                    {vidlist}
                                </div>
                            </div>
                        ) : (
                            <div className="container">
                                <h2 align="left">Presentation style</h2>
                                <p align="left">One player plays all your videos in order, with selectable chapters and videos.</p>

                                <div onClick={() => this.setState({vidOpen: true})} className="mainVidCard" style={{background: `url('/images/videoBg.jpg') no-repeat`, backgroundSize: 'cover'}}>
                                    <div className="content">
                                        <span style={{fontSize: '3em', color: '#F26724'}}><i className="fas fa-play"></i></span>
                                    </div>
                                </div>

                                {/* <button onClick={() => this.setState({compatibilityMode: true})} style={{
                                    margin: '1em',
                                    padding: '1em',
                                    fontSize: 'inherit'
                                }}>Having trouble viewing videos?</button> */}

                            </div>
                        )}
                    </div>
                ) : (
                    <div className="videoContainer">
                        <div className="container">
                            <h2>{this.state.eventTimerText}</h2>
                        </div>
                    </div>
                )}

                {/* <div className="extraVids" style={{background: '#3c4447'}}>
                    <div className="container">
                        <h2 style={{margin: '1em'}}>Sustainable Product Development at Ergon</h2>

                        <div className="topRow">
                            <div>
                            <p style={{margin: '1em'}}>Kris Patrick, Chief Operating Officer, talks with leaders from Process Oils, Asphalt & Emulsions, Specialty Chemicals and Corporate Business Development about a variety of innovation efforts around sustainable products.</p>
                            <p style={{margin: '1em'}}>We think Ergon is uniquely suited to succeed in this area based on our history and on the DNA of our company.</p>
                            </div>
                            <div className="card" style={{margin: '1em auto', width: '80%'}}>
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Esg Intro Feat. Kris Patrick-5.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 1;
                                    }} src="/vids/Esg Intro Feat. Kris Patrick-5.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="extraGrid">
                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Esg - Process Oils Feat. Nick White-3.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 1;
                                    }} src="/vids/Esg - Process Oils Feat. Nick White-3.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Esg - Asphalt Emulsions Feat. Dr. Gaylon Baumgardner-1.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 1;
                                    }} src="/vids/Esg - Asphalt Emulsions Feat. Dr. Gaylon Baumgardner-1.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Esg - Specialty Chemicals Feat. Ken Cooley-4.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 1;
                                    }} src="/vids/Esg - Specialty Chemicals Feat. Ken Cooley-4.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Esg - Business Development Feat. Craig Busbea-2.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 1;
                                    }} src="/vids/Esg - Business Development Feat. Craig Busbea-2.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}

                {this.state.extrasUnlocked ? (
                    <div className="extraVids">
                        <div className="container">
                            <h2>Playlist Style</h2>
                            <p>Watch videos at will</p>
                            <div className="extraGrid">
                                <div className="card">
                                    <div className="vid">
                                        <video onClick={e => {
                                            this.setState({extraVid: "/vids/bees.mp4"})
                                        }} onLoadStart={e => {
                                            e.currentTarget.currentTime = 14;
                                        }} src="/vids/bees.mp4"></video>
                                        <div className="playbtn"><i className="fas fa-play"></i></div>
                                    </div>
                                    <p>Honeybees</p>
                                </div>
                                <div className="card">
                                    <div className="vid">
                                        <video onClick={e => {
                                            this.setState({extraVid: "/vids/bbb.mp4"})
                                        }} onLoadStart={e => {
                                            e.currentTarget.currentTime = 14;
                                        }} src="/vids/bbb.mp4"></video>
                                        <div className="playbtn"><i className="fas fa-play"></i></div>
                                        </div>
                                    <p>Big Buck Bunny</p>
                                </div>
                                <div className="card">
                                    <div className="vid">
                                        <video onClick={e => {
                                            this.setState({extraVid: "/vids/tos.mp4"})
                                        }} onLoadStart={e => {
                                            e.currentTarget.currentTime = 14;
                                        }} src="/vids/tos.mp4"></video>
                                        <div className="playbtn"><i className="fas fa-play"></i></div>
                                    </div>
                                    <p>Tears of Steel</p>
                                </div>
                                <div className="card">
                                    <div className="vid">
                                        <video onClick={e => {
                                            this.setState({extraVid: "/vids/arri.mp4"})
                                        }} onLoadStart={e => {
                                            e.currentTarget.currentTime = 14;
                                        }} src="/vids/arri.mp4"></video>
                                        <div className="playbtn"><i className="fas fa-play"></i></div>
                                    </div>
                                    <p>ARRI</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''}

                {this.state.eventData !== null && this.state.vidOpen ? <VideoPlayer onClose={() => {
                    this.setState({vidOpen: false});
                    this.props.refreshUser();
                }} user={this.props.user} eventData={this.state.eventData} /> : ''}

                {this.state.extraVid !== null ? <ExtraVideoPlayer user={this.props.user} onClose={() => {
                    this.setState({extraVid: null});
                }} src={this.state.extraVid} /> : ''}

                {this.props.user.rank >= 1 && this.state.adminPanelOpen ? <AdminPanel triggerReport={this.props.triggerReport} eventData={this.state.eventData} user={this.props.user} onClose={() => this.setState({adminPanelOpen: false})} /> : ''}

            </div>
        )
    }
}
