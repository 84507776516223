import React, { Component } from 'react';
import './Footer.css';

export default class Footer extends Component {
    render() {
        return (
            <div className="Footer">
                <div className="container">
                    <img className="footerLogo" src="/images/proviswhite.png" />
                    <div className="links">
                        <div>
                            <p>321 US-51 D</p>
                            <p>Ridgeland, MS 39157</p>
                            <p>+1 601-790-0155</p>

                            
                        </div>
                        <div>
                            <p style={{marginTop: '1rem'}}>ProVis, Inc.</p>
                            <p>Copyright &copy; 2021. All Rights Reserved.</p>
                            {/* <ul>
                                <li><a href="https://ergon.com/industry/refining_marketing">Refining &amp; Marketing</a></li>
                                <li><a href="https://ergon.com/industry/specialy_chemicals">Specialty Chemicals</a></li>
                                <li><a href="https://ergon.com/industry/asphalt_emulsions">Asphalt &amp; Emulsions</a></li>
                                <li><a href="https://ergon.com/industry/midstreamLogistics">Midstream &amp; Logistics</a></li>
                                <li><a href="https://ergon.com/industry/oil_gas">Oil &amp; Gas</a></li>
                                <li><a href="https://ergon.com/industry/construction_realestate">Construction &amp; Real Estate</a></li>
                            </ul> */}
                        </div>
                        <div>
                            {/* <ul>
                                <li><a href="https://ergon.com/about">About</a></li>
                                <li><a href="https://ergon.com#">SDS</a></li>
                                <li><a href="https://ergon.com/crudeoil">Crude Prices</a></li>
                                <li><a href="https://ergon.com/ergon/corporate_services">Corporate</a></li>
                                <li><a href="https://ergon.com/updates">Updates</a></li>
                                <li><a href="https://ergon.com/ergon/contact">Contact</a></li>
                            </ul> */}
                        </div>
                        <div>
                            {/* <ul>
                                <li><a href="https://ergon.com/privacy-policy">Privacy Policy</a></li>
                                <li><a href="https://ergon.com/files/EQS-PO-003-Ergon-Environmental-Policy.pdf">Environmental Policy</a></li>
                                <li><a href="https://ergon.com/files/EQS-PO-001-ErgonQualityPolicy.pdf">Ergon Quality Policy</a></li>
                                <li><a href="https://ergon.com/files/PO-TERMS-CON.pdf">Purchase Order Terms &amp; Conditions</a></li>
                                <li><a href="https://ergon.com/files/Ergon-Safety-Commitment.pdf">Safety Commitment</a></li>
                                <li><a href="https://ergon.com/california-transparency-in-supply-chains-act">California Transparency in<br />Supply Chains Act</a></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
