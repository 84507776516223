import React, { Component } from 'react';
import './Header.css';

export default class Header extends Component {
    render() {
        return (
            <div className="Header">
                <div className="container">
                    <div className="grid" style={{
                        gridTemplateColumns: 'auto 1fr auto',
                        alignItems: 'center'
                    }}>
                        <img className="headerimg" src="/images/banner2021.png" />
                        <div style={{padding: '1em', fontWeight: 'bold', color: 'grey'}}>
                            <p>Video Platform Presentation</p>
                        </div>
                        {/* <nav>
                            <ul className="menu">
                                <li> Tab 1
                                    <ul className="submenu">
                                        <li>Item 1</li>
                                        <li>Item 2</li>
                                    </ul>
                                </li>
                                <li>Tab 2</li>
                            </ul>
                        </nav> */}
                    </div>
                </div>
            </div>
        )
    }
}
